<div class="content-container">
    <zui-sidenav
        #sidenav
        [sidenavParams]="sidenavConfig"
        [header]="sidenavHeader"
        [footer]="sidenavFooter"
        (emitSelectedCompany)="selectCompany($event)"
        [hideSidenav]="hideSidenav$ | async"
    >
        <zui-progress-spinner
                *ngIf="isLoading$ | async"
                class="progress-spinner"
                color="accent"
                diameter=256
        ></zui-progress-spinner>
        <router-outlet></router-outlet>
    </zui-sidenav>
    <zui-footer-component></zui-footer-component>
</div>
